<template>
    <div class="c-staff__table editable_table flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            single-select
            item-key="id"
            :headers="headers"
            :items="tableData"
            :items-per-page="this.pageSize"
            :page.sync="page"
            :footer-props="footerProps"
            class="a-table w-full capitalize"
            :server-items-length="total"
            data-cy="clientList"
            :loading="loading"
            @click:row="rowClickHandler"
            @update:options="updatePagination"
        >
            <template class="" v-slot:item.total="{ item }">
                <!-- No of episodes for this client -->
                {{ item.episodes.length }}
            </template>
            <template class="" v-slot:item.open="{ item }">
                <EpisodeOpenTooltip
                    v-if="getNumOpenEpisodes(item.episodes) > 0"
                    :episode-number="getNumOpenEpisodes(item.episodes)"
                />
            </template>
            <template class="" v-slot:item.locationProgramId="{ item }">
                {{getSiteSection(item)}}
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{ item }">
                <div class="text-right">
                    <a
                        href="#"
                        @click.prevent="rowClickHandler(item)"
                        class="hover:text-primary mx-2"
                    >
                        <fa-icon icon="chevron-right" class="text-grey-2 ml-3" />
                    </a>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import StatusBadge from '@/components/partials/StatusBadge'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import EpisodeOpenTooltip from '@/components/shared/EpisodeOpenTooltip'
import { EpisodeHelpers } from '@/components/shared/mixins/episodeMixins'

export default {
    name: 'ClientTable',
    mixins: [HttpMixin, MessageDialog, BackendHelpers, EpisodeHelpers],
    components: {StatusBadge, EpisodeOpenTooltip},
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        summary: {
            type: Boolean,
            default: false
        },
        pageSize: {
            type: Number,
            default: 25
        },
        total: {
            type: Number,
            default: 5
        },
        loading: {
            type: Boolean,
            default: false
        },
        assignedToMe: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            emptyText: 'No clients found for the search criteria',
            accreditationStatus: [
                {value: 'OPEN', label: 'OPEN', text: 'OPEN'},
                {value: 'CLOSED', label: 'CLOSED', text: 'CLOSED'},
            ],
            duration: 3000,
            type: '',
            message: '',
            footerProps: {
                'items-per-page-options': [5, 10, 20, 30, 40]
            },
            headers: [
                { text: 'id', align: 'left', value: 'clientId', class: 'id--header' },
                { text: 'first name', align: 'left', value: 'firstName', class: 'first-name--header' },
                { text: 'last name', align: 'left', value: 'lastName', class: 'last-name--header' },
                { text: 'site - section', align: 'left', value: 'locationProgramId', sortable: false, class: 'type--header' },
                { text: 'total episodes', align: 'left', value: 'total', class: 'w-150 reg--header' },
                { text: 'open program', align: 'left', value: 'open', sortable: false, class: 'w-150 reg--header' },
                { text: '', align: 'left', value: 'editRecord', fixed: true},
            ],
            page: 1
        }
    },
    methods: {
        updatePagination(options) {
            this.$emit('update-pagination', options)
        },
        setPage(page) {
            this.page = page
        },
        rowClickHandler(item) {
            // console.log('edit item click handler')
            // console.log('item:', item)
            this.$store.commit('SET_CURRENT_EPISODE', null)
            this.$store.commit('SET_CLIENT_ID', item.clientId)
            this.$store.commit('SET_ASSIGNED_TO_ME', this.assignedToMe)
            this.$router.push({name: 'episodes', params: {clientId: item.clientId}})
        },
        getStatus(status) {
            switch(status) {
                case 'OPEN':
                    return 'PROGRAM OPEN'
                case 'PENDING':
                    return 'ASSESSED PENDING'
                default:
                    return 'PROGRAM OPEN'
            }
        },
        getStatusColour(status) {
            switch(status) {
                case 'OPEN':
                    return 'text-green'
                case 'PENDING':
                    return 'text-yellow'
                default:
                    return 'text-green'
            }
        },
        getNumOpenEpisodes(episodes) {
            if (!episodes || !episodes.length) return 0
            return episodes.filter((e) => e.status.toLowerCase() === 'open').length
        },
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            siteSections: state => state.app.siteSections,
        }),
        states() {
            let states = this.staticChoices['generic_address.state'] || []
            if (states && states.length > 0) {
                states = states.map(s => {
                    return {
                        label: s.label,
                        value: s.value,
                        text: s.value
                    }
                })
            }
            return states
        }
    },
    mounted() {
        if (this.summary) {
            this.footerProps = {}
        }
    }
}
</script>
